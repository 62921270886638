import React from "react";
import Layout from "../../components/layout"
import SearchResultPage from "../../templates/search-results-template-holiday-lets";
import { useStaticQuery, graphql } from "gatsby"
import StaticComponentImageContent from "../../components/StaticComponent/StaticComponentImageContent";
import StaticComponentImageContentBlue from "../../components/StaticComponent/StaticComponentImageContentBlue";
import AboutSection from "../../components/AboutSection/AboutSection";
import FAQModule from "../../components/FAQModule/FAQModule";
import parse from "html-react-parser"
import RatingSection from "../../components/RatingSection/RatingSection";
const SearchResultResiSales = (props) => {
    const pageQuery = useStaticQuery(graphql`
            query {
          glstrapi {
            pages(where: {title: "Holiday Lets"}) {
                alias
                banner_content
                banner_cta_label
                banner_right_content
                banner_search_default_select
                choose_form
                content
                layout
                page_class
                show_reviews
                show_search
                title
                imagetransforms
                id
                seo_meta {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                menu {
                  label
                  id
                }
                banner_image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        width: 1800
                        quality: 100
                        layout: CONSTRAINED
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
                }
                banner_cta_link {
                  id
                  slug
                }
                add_components {
                  __typename
                  ... on GLSTRAPI_ComponentComponentsSelectCollection {
                    choose_collection
                    title
                    description
                  }
                  ... on GLSTRAPI_ComponentComponentsIntroSection {
                    text_block {
                      title
                      description
                      title_below_image {
                        url
                      }
                    }
                    link {
                      id
                      label
                      menu_item {
                        id
                      }
                    }
                  }
                  ... on GLSTRAPI_ComponentComponentsShowCustomerReviews {
                    show
                  }
                  ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {
                    layout_type
                    Button_CTA {
                      label
                      menu_item {
                        id
                      }
                      Secondary_Url
                    }
                    media_block {
                      Video_Url
                      media_file {
                        url
                        alternativeText
                      }
                    }
                    text_block {
                      description
                      title
                    }
                  }
                  ... on GLSTRAPI_ComponentComponentsFullWidthTwoColumnBlock {
                    column_1_cta_label
                    column_1_sub_title
                    column_1_title
                    column_2_sub_title
                    column_2_title
                    column_1_cta_link {
                      id
                    }
                    column_2_cta_label
                    column_2_cta_link {
                      id
                    }
                  }
                  ... on GLSTRAPI_ComponentComponentsShowModule {
                    id
                    module {
                      module_type
                      section_name
                      add_new_text_with_image_module {
                        layout_type
                        text_block {
                          description
                          title
                        }
                        media_block {
                          media_file {
                            url
                            alternativeText
                            url_sharp {
                              childImageSharp {
                                gatsbyImageData(
                                  formats: WEBP
                                  width: 500
                                  quality: 100
                                  layout: CONSTRAINED
                                  transformOptions: { cropFocus: CENTER, fit: COVER }
                                )
                              }
                            }
                          }
                        }
                        Button_CTA {
                          label
                          menu_item {
                            id
                          }
                          Secondary_Url
                        }
                      }
                      add_new_stats_or_service {
                        description
                        icon_class_name
                        title
                        properties_count
                      }
                    }
                  }
                  ... on GLSTRAPI_ComponentComponentsFaq {
                    _id
                    faq_intro
                    choose_faqs {
                      Answer
                      Question
                    }
                  }
                  ... on GLSTRAPI_ComponentComponentsContentWithPoints {
                    Add_Content {
                      Content
                      Title
                    }
                  }
                }
              }
            }
          }
        `)
        console.log(pageQuery);
        
    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const normalizedFullpath = fullpath;
    const [scroll, setScroll] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, [])
    const data = pageQuery.glstrapi.pages[0];
    console.log(data,'-dataresult');
    
    return (
        
        <div className='search-results-page holiday-lets'>
            <Layout isHoliday={true}>
                <section className={scroll ? "search-banner search-banner--fixed" : "search-banner"}>
                    {/* <SearchSelect /> */}
                    <SearchResultPage
                        locationname="/holiday-lets/"
                        pcategorytype="short-lettings"
                        multipcategorytype={['short-lettings']}
                        ptype="lettings"
                        ptypetag="for-sale"
                        completionstatus="Off Plan"
                        pstatustype={['for-sale', 'sold-stc', 'sold', 'under-offer']}
                        fullpathone={fullpathone}
                        fullpathname={fullpath}
                        {...props}
                        psearch="Lettings"
                    />
                </section>
                
                { normalizedFullpath == "/holiday-lets/in-dubai/" &&(
                data && data?.add_components.map((module, i) => {
                    return (
                        <>
                            {module.__typename === "GLSTRAPI_ComponentComponentsIntroSection" &&
                                <AboutSection {...data} {...module} />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsShowCustomerReviews" &&
                                <RatingSection />
                            }

                            {module.__typename === "GLSTRAPI_ComponentComponentsTextBlockWithImage" &&
                                <>
                                    {module.layout_type == "left_align_image_blue_background" ?
                                        <StaticComponentImageContentBlue {...data} {...module} /> :
                                        <StaticComponentImageContent {...data} {...module} />}
                                </>
                            }

                            {module.__typename == "GLSTRAPI_ComponentComponentsFaq" &&
                                <FAQModule {...module} />
                            }
                        </>
                        )
                    }))
                }
            </Layout>
        </div>
    );
};

export default SearchResultResiSales;
